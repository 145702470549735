import React, { useEffect, useState, useCallback } from "react";
import "./mainpage.scss";
import { FaCheck } from "react-icons/fa";
import PaginationPart from "./pagination";
import ReactPaginate from "react-paginate";
import { CiFilter } from "react-icons/ci";
import { IoChevronBackCircle } from "react-icons/io5";
import { MdClear } from "react-icons/md";
import axiosInstance from "../helper";
import FabricCollections from "../fabric/fabric";
import { TailSpin } from "react-loader-spinner";
const ITEMS_PER_PAGE = 15;

const FactCollections = () => {
  const [isLoding, setIsLoding] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isGarmentSelected, setIsGarmentSelected] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [categoryData, setCategoryData] = useState({
    gender: [],
    garmentTypes: [],
    categories: [],
    subCategories: [],
  });
  const [products, setProducts] = useState([]);
  const [gender_api, setGender_api] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [innerFilterOptions, setInnerFilterOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const filteredProducts =
    selectedCategories.length === 0 ? products : products;
  const currentItems = filteredProducts.slice(
    itemOffset,
    itemOffset + ITEMS_PER_PAGE
  );
  const pageCount = Math.ceil(filteredProducts.length / ITEMS_PER_PAGE);
  const fetchData = useCallback(async (isGarment) => {
    try {
      setIsLoding(true);

      const [gender, garmentTypes, categories, subCategories, productsList] =
        await Promise.all([
          axiosInstance.get("product/gender-type/"),
          axiosInstance.get("product/garment-type/"),
          axiosInstance.get("product/category/"),
          axiosInstance.get("product/sub-category/"),
          axiosInstance.get("product/product-list/"),
        ]);

      setCategoryData({
        gender: gender.data.map((x) => ({
          ...x,
          type: "category__gender_type",
        })),
        garmentTypes: garmentTypes.data.map((x) => ({
          ...x,
          type: "category__garment_type",
        })),
        categories: categories.data.map((x) => ({ ...x, type: "category" })),
        subCategories: subCategories.data.map((x) => ({
          ...x,
          type: "subCategory",
        })),
      });

      setProducts(productsList.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoding(false);
    }
  }, []);

  useEffect(() => {
    fetchData(isGarmentSelected);
    setSelectedCategories([]);
  }, [isGarmentSelected, fetchData]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * ITEMS_PER_PAGE) % filteredProducts.length;
    console.log({ event, newOffset });

    setItemOffset(newOffset);
  };

  const handleCheckboxChange = useCallback((category) => {
    setSelectedCategories((prev) =>
      prev.some((c) => c.name === category.name)
        ? prev.filter((c) => c.name !== category.name)
        : [...prev, category]
    );
  }, []);

  const handleRemoveFilter = useCallback((category) => {
    setSelectedCategories((prev) =>
      prev.filter((c) => c.name !== category.name)
    );
  }, []);

  const handleInnerFilterMenu = useCallback(
    (filterType) => {
      setSelectedFilter(filterType);
      const filterMap = {
        Gender: "gender",
        "Garments-Types": "garmentTypes",
        Category: "categories",
        "Sub-Category": "subCategories",
      };
      setInnerFilterOptions(categoryData[filterMap[filterType]] || []);
    },
    [categoryData]
  );

  useEffect(() => {
    const fetchFilteredProducts = async () => {
      if (selectedCategories.length === 0) return;
      setIsLoding(true);

      const filter = selectedCategories
        .map((item) => `${item.type}=${item.id}`)
        .join("&");
      try {
        const response = await axiosInstance.get(
          `product/product-list?${filter}`
        );
        setProducts(response.data);
        setIsLoding(false);
      } catch (error) {
        console.error("Error fetching filtered products:", error);
      }
    };

    fetchFilteredProducts();
  }, [selectedCategories]);

  useEffect(() => {
    const fetchGenderBasedProducts = async () => {
      setIsLoding(true);

      const genderBase = selectedCategories.find(
        (x) => x.type === "category__gender_type"
      );
      if (!genderBase) {
        fetchData(false);
        setGender_api(false);
        return;
      }

      if (gender_api) {
        return;
      }

      try {
        const response = await axiosInstance.get(
          `product/gender-based-products/?gender_type_id=${genderBase.id}`
        );
        setCategoryData((prev) => ({
          ...prev,
          garmentTypes: response.data.garment_types.map((x) => ({
            ...x,
            type: "category__garment_type",
          })),
          categories: response.data.categories.map((x) => ({
            ...x,
            type: "category",
          })),
          subCategories: response.data.subcategories.map((x) => ({
            ...x,
            type: "subCategory",
          })),
        }));
        setIsLoding(false);

        setGender_api(true);
      } catch (error) {
        console.error("Error fetching gender-based products:", error);
      }
    };

    fetchGenderBasedProducts();
  }, [selectedCategories, fetchData]);

  const renderFilterOptions = (options, title) => (
    <>
      <div
        style={{
          fontSize: "20px",
          fontFamily: "Poppins",
          fontWeight: 400,
          lineHeight: "20px",
          textAlign: "left",
          color: "#000000",
          marginTop: title === "Gender" ? "0px" : "20px",
          marginBottom: "24px",
        }}
      >
        {title}
      </div>
      {options.map((option) => (
        <div key={option.name} className="filt-div">
          <input
            type="checkbox"
            id={option.name}
            checked={selectedCategories.some((x) => x.name === option.name)}
            onChange={() => handleCheckboxChange(option)}
          />
          <label htmlFor={option.name}>{option.name}</label>
        </div>
      ))}
    </>
  );

  return (
    <div className="ecommerce-container">
      {/* Main content and filter UI */}
      <main className="fac_coll-part1">
        <h1>Our exclusive Products</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, officia
          quos incidunt, vero id quidem libero rerum dolore quod, nesciunt ero
          id quidem libero rerum dolore quod, nesciunt
        </p>
        <aside className="selectedCategorie">
          <button
            className={isGarmentSelected ? "selected-gar" : "selected-switch"}
            onClick={() => setIsGarmentSelected(true)}
          >
            {isGarmentSelected && <FaCheck />} Garments
          </button>
          <button
            className={!isGarmentSelected ? "selected-fab" : "selected-switch"}
            onClick={() => setIsGarmentSelected(false)}
          >
            {!isGarmentSelected && <FaCheck />} Fabric
          </button>
        </aside>
      </main>

      {isGarmentSelected ? (
        <>
          <section className="collections-display">
            <header className="coll-dis-head">
              <div className="collection-filter-icon">
                <p>
                  <span>{products.length} Items</span>
                </p>
                <div
                  className="filt-head"
                  onClick={() => setIsFilterOpen(true)}
                >
                  <span>Filters</span>
                  <CiFilter />
                </div>
                {/* Filter modal */}
                {isFilterOpen && (
                  <div className="active-filter">
                    <div
                      className={`active-filter ${
                        isFilterOpen ? "show" : "inactive-filter"
                      }`}
                    >
                      <div className="inner-filter-head">
                        <div>
                          <IoChevronBackCircle
                            onClick={() => setIsFilterOpen(false)}
                          />
                          <p>Filters</p>
                        </div>

                        <button
                          onClick={() => {
                            if (selectedCategories.length > 0) {
                              setSelectedCategories([]);
                            }
                          }}
                        >
                          Clear Filters
                        </button>
                      </div>
                      <aside className="inner-filter-data">
                        <div className="inner-filtter-side1">
                          {[
                            "Gender",
                            "Garments-Types",
                            "Category",
                            "Sub-Category",
                          ].map((x) => {
                            return (
                              <h3
                                onClick={() => handleInnerFilterMenu(x)}
                                style={{
                                  backgroundColor:
                                    selectedFilter === x
                                      ? "#FFE6CB"
                                      : "transparent",
                                }}
                              >
                                {x}
                              </h3>
                            );
                          })}
                        </div>
                        <div className="inner-filtter-side2">
                          {innerFilterOptions.map((subcategory) => (
                            <div
                              key={subcategory.name}
                              className="inner-filter-check"
                            >
                              <input
                                type="checkbox"
                                id={subcategory.name}
                                value={subcategory.name}
                                checked={selectedCategories.some(
                                  (x) => x.name === subcategory.name
                                )}
                                onChange={() =>
                                  handleCheckboxChange(subcategory)
                                }
                              />
                              <label htmlFor={subcategory.name}>
                                {subcategory.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </aside>
                      <div className="inner-filter-footer">
                        <p>{products.length} Results</p>
                        <button onClick={() => setIsFilterOpen(false)}>
                          Apply Filters
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </header>
            <p className="product_count">
              Products <span> {products.length} Items </span>
            </p>
            <div className="coll-dis-filt-head">
              <div className="coll-dis-filt-head-first">
                <h5
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "23px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    textAlign: "left",
                    paddingBottom: "9px",
                    paddingLeft: "30px",
                  }}
                >
                  Filters
                </h5>
                <p
                  style={{
                    fontFamily: "Inter, sans-serif",
                    fontSize: "15px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    textAlign: "left",
                    color: "#000000",
                  }}
                  className="font-body text-medium font-normal leading-16 text-left"
                  onClick={() => {
                    if (selectedCategories.length > 0) {
                      setSelectedCategories([]);
                    }
                  }}
                >
                  Clear All
                </p>
              </div>

              <div
                className={
                  selectedCategories.length > 0
                    ? "selected-category"
                    : "selected-category-empty"
                }
              >
                {selectedCategories.map((category) => (
                  <button
                    key={category.name}
                    onClick={() => handleRemoveFilter(category)}
                  >
                    {category.name} <MdClear />
                  </button>
                ))}
              </div>
            </div>
            <aside className="coll-dis-section-container">
              <main className="coll-dis-section">
                <aside className="coll-dis-filt">
                  {renderFilterOptions(categoryData.gender, "Gender")}
                  {renderFilterOptions(
                    categoryData.garmentTypes,
                    "Garment-Types"
                  )}
                  {renderFilterOptions(categoryData.categories, "Categories")}
                  {renderFilterOptions(
                    categoryData.subCategories,
                    "Sub-Categories"
                  )}
                </aside>
                <aside className="coll-dis-gallery-container">
                  {isLoding ? (
                    <div className="is_loading">
                      <TailSpin
                        type="ThreeDots"
                        color="black"
                        height={80}
                        width={80}
                      />
                      <h4> Loading . . .</h4>
                    </div>
                  ) : currentItems.length === 0 ? (
                    <h1 className="products-not-found">No products found</h1>
                  ) : (
                    <PaginationPart filteredProducts={currentItems} />
                  )}
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                    className="paginate-cont"
                  />
                </aside>
              </main>
            </aside>
          </section>
        </>
      ) : (
        <FabricCollections />
      )}
    </div>
  );
};

export default FactCollections;
