import React from "react";
import "./aboutus4.scss";
import tshirt from "../../assets/image1.png";
import men from "../../assets/image3.png";
import garments from "../../assets/image4.png";
import sports from "../../assets/image2.png";
import shirt from "../../assets/image5.png";
import cornerImage from "../../assets/aboutus.png"; // Import the new image

const Aboutus4 = () => {
  return (
    <div className="abouts4-container">
      <div className="text-section">
        <h1 className="title">Stitch Sustainable and Profitable</h1>
        <h4
          style={{
            lineHeight: "64.91px",
            color: "#C19A6F",
            fontFamily: "Viaoda Libre",
            fontWeight: "400",
            fontSize: "46px",
          }}
        >
          OUR VALUES
        </h4>
        <div className="text-sub-section">
          <div className="text">
            As we do not own the factories that make your products, we work with
            carefully selected suppliers, many of which have worked with us for
            many years. We work hard to ensure that your products are made with
            respect for the environment, in good working conditions, where
            workers’ rights are respected.
          </div>
        </div>
      </div>

      <div className="grid-container">
        
        <div className="gallery">
          <aside className="gallery-first">

            {/* Add the new image */}
            <div className="img-gallery-first">
            <img src={cornerImage} alt="Corner" className="corner-image" />{" "}
              <img src={shirt} alt="" />
            </div>

            <div className="gallery-text first-text horizon-align">
              <h1>vision</h1>
            </div>
            <div className="img-gallery ">
              <img src={men} alt="" />
            </div>
          </aside>
          <aside className="gallery-second">
            <div className="gallery-text first-text verti-align">
              <h1>value</h1>
            </div>
            <div className="img-gallery mid-img">
              <img src={garments} alt="" />
            </div>
            <div className="gallery-text second-text verti-align">
              <h1>mission</h1>
            </div>
          </aside>
          <aside className="gallery-third">
            {" "}
            <div className="img-gallery">
              <img src={sports} alt="" />
            </div>
            <div className="gallery-text second-text horizon-align">
              <h1>feed-back</h1>
            </div>
            <div className="img-gallery">
              <img src={tshirt} alt="" />
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default Aboutus4;
