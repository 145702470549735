import React, { useState } from "react";
import "./fabric.scss";
import Modal from "./Modal";
import GallerySwiper from "./gallary-swiper";

const PaginationPart = ({ filteredProducts }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [particularFabric, setParticularFabric] = useState({});

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div className="coll-dis-gallery">
        {filteredProducts.map((fabric) => (
          <div
            key={fabric.id}
            className="disp-product-card"
            onClick={() => {
              openModal();
              setParticularFabric(fabric);
            }}
          >
            <img
              src={`${fabric.images[0]?.image}`}
              alt={fabric.fabricName}
              className="coll-img"
            />
            <div className="disp-product-card-text">
              <h6>{fabric.fabricName}</h6>
              <p>{fabric.description}</p>
            </div>
          </div>
        ))}
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <aside className="product-page-img">
          <div className="swipe">
            <GallerySwiper images={particularFabric.images} />
          </div>
          <div>
            <h3>{particularFabric.fabricName}</h3>
            <p>
              {particularFabric.description ||
                `No Description Available for ${particularFabric.fabricName}`}
            </p>
          </div>
        </aside>
      </Modal>
    </>
  );
};

export default PaginationPart;
