import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./product.scss";
import GallerySwiper from "./gallary-swiper";
import { FiUpload } from "react-icons/fi";
import axiosInstance from "../helper";
import ShippingInformation from "../shipping-information/home";
import { SketchPicker } from "react-color";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"; // Add this import

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CustomMultiSelect from "./CustomMultiSelect";

const ProductPage = () => {
  const navigate = useNavigate();
  const [setimage, setSetImage] = useState(null);

  const [togalColor, setTogalColor] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [color, setColor] = useState("#bebebe");
  const [moq, setMoq] = useState("");
  const [gsm_variants, setGsmVariant] = useState([]);
  const [wash_types, setWash_types] = useState([]);
  const [print_details, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isGsmOpen, setIsGsmOpen] = useState(false); // Add this state
  const [isWashOpen, setIsWashOpen] = useState(false); // Add this state

  const [shippingInfo, setShippingInfo] = useState({
    full_name: "",
    email: "",
    contact_number: "",
    company_name: "",
    delivery_note: "",
  });

  const fileInputRef = useRef(null);
  const location = useLocation();
  const myData = location.state;
  const [selectedCities, setSelectedCities] = useState([]);
  const cities = ["New York", "Rome", "London", "Istanbul", "Paris"];
  const multiSelectRef = useRef(null);
  console.log(myData);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  useEffect(() => {
    async function handlefilterFetchdata() {
      try {
        const filter_product = await axiosInstance.get(
          `/product/product/${myData.id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setSelectedProduct(filter_product.data);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    }
    handlefilterFetchdata();
  }, [myData.id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        multiSelectRef.current &&
        !multiSelectRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmitShipping = async () => {
    const requestData = {
      ...shippingInfo,
      contact_number: `+${shippingInfo.contact_number}`,
      product: selectedProduct.id,
      wash_types,
      moq,
      color,
      gsm_variants,
      print_details,
    };

    console.log({ requestData });

    try {
      const response = await axiosInstance.post(
        "/product/product-orders/",
        requestData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      toast.success("Successfully sent!");

      setShowModal(false); // Close modal after successful submission

      navigate("/gallery");
    } catch (error) {
      toast.error("something wrong contact to us!");

      setShowModal(false);
    }
  };

  // Modify this to work with CustomMultiSelect
  const handleChange_gsm = (value) => {
    setGsmVariant(value);
  };

  const handleChange_washtype = (event) => {
    const { options } = event.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    setWash_types(selectedValues);
  };

  return (
    <div className="product-container">
      <main className="product-page-part1">
        <div>
          <h1>Our exclusive Products</h1>
        </div>
        <div>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic,
            officia quos incidunt, <br />
            vero id quidem libero rerum dolore quod, nesciunt consequatur
          </p>
        </div>
      </main>
      <section className="product-page-main">
        <aside className="product-page-img">
          <div className="product-page-img-1">
            <img
              src={` ${
                myData?.first_image
                  ? setimage
                    ? `https://hash-stitch.onrender.com${setimage}`
                    : `https://hash-stitch.onrender.com${myData?.first_image}`
                  : myData.images[0]?.image
              } `}
              alt={`${myData.name}`}
            />
          </div>
          <div className="swiper-navigation">
            <div className="swiper-button-prev">
              <IoIosArrowBack />
            </div>
            <GallerySwiper
              images={
                myData?.first_image ? selectedProduct.images : myData.images
              }
              setSetImage={setSetImage}
            />
            <div className="swiper-button-next">
              <IoIosArrowForward />
            </div>
          </div>
        </aside>
        <aside className="product-page-content">
          <h1>{selectedProduct.name}</h1>
          <div className="product-page-content-part1">
            <p>{selectedProduct.description}</p>
          </div>

          <div className="product-page-content-part2">
            <div>
              <h4>Fabric</h4>
              <p>{selectedProduct?.fabric?.fabricName || "cotton"}</p>
            </div>
            <h4>Colour/Hue</h4>

            <div className="huecolor-div">
              <button
                style={{
                  backgroundColor: color,
                  color: "grey",
                  padding: "5px 6px",
                  borderRadius: "5px",
                  border: "1px solid grey",
                  width: "35px",
                  height: "35px",
                }}
              ></button>
              <span style={{ fontWeight: "600" }}>{color}</span>
              <button
                onClick={() => setTogalColor(!togalColor)}
                className="choosebtn"
              >
                Choose your hue here
              </button>
            </div>
            <SketchPicker
              color={color}
              onChangeComplete={(col) => {
                setColor(col.hex);
                setTogalColor(!togalColor);
              }}
              onChange={(col) => {}}
              className={`${togalColor ? "" : "huecolor-com"}`}
            />

            <div className="product-page-content-form">
              <div>
                <label htmlFor="ss">
                  GSM Variants <span>Add your own GSM</span>
                </label>
                <CustomMultiSelect
                  options={
                    selectedProduct?.gsm_variants?.map((gsmv) => gsmv.value) ||
                    []
                  }
                  selectedOptions={gsm_variants}
                  setSelectedOptions={setGsmVariant}
                  placeholder="Select GSM Variants"
                  isOpen={isGsmOpen}
                  setIsOpen={setIsGsmOpen}
                  noOptionsMessage="No options available"
                />
              </div>

              <div className="no-qty">
                <label htmlFor="printDetails">MOQ</label>
                <input
                  type="text"
                  placeholder="Quantity..."
                  value={moq}
                  onChange={(e) => setMoq(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="ww">Washing Type</label>
                <CustomMultiSelect
                  options={
                    selectedProduct?.wash_types?.map((gsmv) => gsmv.name) || []
                  }
                  selectedOptions={wash_types}
                  setSelectedOptions={setWash_types}
                  placeholder="Select Washing types"
                  isOpen={isWashOpen}
                  setIsOpen={setIsWashOpen}
                  noOptionsMessage="No options available"
                />
              </div>
              <div>
                <label htmlFor="">Upload Your Tech Stack</label>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <button onClick={handleButtonClick}>
                  <FiUpload /> Upload
                </button>
              </div>
            </div>

            <button className="order-product" onClick={openModal}>
              Order the Product
            </button>
            <ShippingInformation
              show={showModal}
              onClose={closeModal}
              shippingInfo={shippingInfo}
              setShippingInfo={setShippingInfo}
              onSubmitShipping={handleSubmitShipping}
            />

            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </div>
        </aside>
      </section>
    </div>
  );
};

export default ProductPage;
