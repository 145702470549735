import React from "react";
import PageHeader from "../../components/pageheader";
import ContactUs from "../../components/contact-us/contactus";
import ProductPage from "../../components/product-details/productDeatails";
import Footer from "../../components/footer/footer";

const ProductDetails = () => {
  return (
    <div>
      <PageHeader
        currentPage={"Service Details"}
        banner={"banner-1 banner-2"}
      />
      <ProductPage />
      <Footer />
    </div>
  );
};

export default ProductDetails;
