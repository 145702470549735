// React Icons For Nav And React.......................
import { FiUser } from "react-icons/fi";
import { VscSearch } from "react-icons/vsc";
import { BsBag } from "react-icons/bs";
// Header Books  Data.......................
import HBook1 from "../assets/headerBook1.png";
import HBook2 from "../assets/headerBook2.png";
import HBook3 from "../assets/headerBook3.png";
// Brands Data.......................
import Brand1 from "../assets/brand1.png";
import Brand2 from "../assets/brand2.png";
import Brand3 from "../assets/brand3.png";
import Brand4 from "../assets/brand4.png";
import Brand5 from "../assets/brand5.png";

// Featured Books Data.......................
import FeaturedBooks1 from "../assets/FeaturedBook1.png";
import FeaturedBooks2 from "../assets/FeaturedBook2.png";
import FeaturedBooks3 from "../assets/FeaturedBook3.png";
import FeaturedBooks4 from "../assets/FeaturedBook4.png";
import FeaturedBooks5 from "../assets/FeaturedBook5.png";

// Selling Data.......................
import popularbook from "../assets/book1.png";

// Popular Books Data.......................

import popularbook1 from "../assets/book1.png";
import popularbook2 from "../assets/book2.png";
import popularbook3 from "../assets/book3.png";
import popularbook4 from "../assets/book4.png";
import popularbook5 from "../assets/book5.png";
import popularbook6 from "../assets/book6.png";
import popularbook7 from "../assets/book7.png";
import popularbook8 from "../assets/book8.png";

// import react Icon.......................
import { ImFacebook, ImBehance } from "react-icons/im";
import { FiInstagram } from "react-icons/fi";
import { RiTwitterXLine } from "react-icons/ri";
import { GrLinkedinOption } from "react-icons/gr";

// lettestArticleData Data .......................
import ArticleImage1 from "../assets/ArticleImage1.png";
import ArticleImage2 from "../assets/ArticleImage2.png";
import ArticleImage3 from "../assets/ArticleImage3.png";

export const navLinks = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Services",
    path: "/services",
  },
  {
    name: "Collections",
    path: "/gallery",
  },
  {
    name: "Sustainabality",
    path: "/sustanability",
  },
  {
    name: "Contact",
    path: "/contact",
  },
  {
    name: "Place Order",
    path: "/gallery",
  },
];

// navRight links Data..............................
export const navRight = {
  managements: [
    {
      id: 1,
      icon: FiUser,
      link: "*",
    },
    {
      id: 2,
      icon: BsBag,
      link: "*",
    },
    {
      id: 3,
      icon: VscSearch,
      link: "*",
    },
  ],
};

export const headerBooks = [
  {
    id: 1,
    img: HBook1,
    title: "About-Us",
    info: "HashStitch was started by  after his success in many clothing businesses. He learned new ways to get materials and make clothes that were different from what others were doing. works all over Europe, Asia, and everywhere, thinking of the world as one big place.",
    btnLink: "*",
  },
  {
    id: 2,
    img: HBook2,
    title: "Simple way of piece life",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eu feugiat <br> amet, libero ipsum enim pharetra hac. Urna commodo, lacus ut magna <br> velit eleifend. Amet, quis urna, a eu.",
    btnLink: "*",
  },
  {
    id: 3,
    img: HBook3,
    title: "Great travel at desert",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eu feugiat <br> amet, libero ipsum enim pharetra hac. Urna commodo, lacus ut magna <br> velit eleifend. Amet, quis urna, a eu.",
    btnLink: "*",
  },
];

export const brandsData = [
  {
    id: 1,
    img: Brand1,
  },
  {
    id: 2,
    img: Brand2,
  },
  {
    id: 3,
    img: Brand3,
  },
  {
    id: 4,
    img: Brand4,
  },
  {
    id: 5,
    img: Brand5,
  },
];

export const featuredBooksData = [
  {
    id: 1,
    img: FeaturedBooks1,
    imgLlink: "*",
    nameLink: "*",
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
  },
  {
    id: 2,
    img: FeaturedBooks2,
    imgLlink: "*",
    nameLink: "*",
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
  },
  {
    id: 3,
    img: FeaturedBooks3,
    imgLlink: "*",
    nameLink: "*",
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
  },
  {
    id: 4,
    img: FeaturedBooks4,
    imgLlink: "*",
    nameLink: "*",
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
  },
  {
    id: 5,
    img: FeaturedBooks5,
    imgLlink: "*",
    nameLink: "*",
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
  },
];

export const sellingBooksData = [
  {
    id: 1,
    img: popularbook,
    infoTitleTop: "By Timbur Hood",
    infoTitle: "Birds gonna be happy",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eu feugiat amet, libero ipsum enim pharetra hac",
    price: "$ 45.00",
    shopbtnLink: "*",
  },
];

export const galleryData = [
  {
    id: 1,
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
    category: "Business",
    image: popularbook1,
  },
  {
    id: 2,
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
    category: "Technology",
    image: popularbook2,
  },
  {
    id: 3,
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
    category: "Adventure",
    image: popularbook3,
  },
  {
    id: 4,
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
    category: "Romantic",
    image: popularbook4,
  },
  {
    id: 5,
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
    category: "Fictional",
    image: popularbook5,
  },
  {
    id: 6,
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
    category: "Business",
    image: popularbook6,
  },
  {
    id: 7,
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
    category: "Technology",
    image: popularbook7,
  },
  {
    id: 8,
    name: "Great travel at desert",
    writer: "Sanchit Howdy",
    price: "$ 38.00 ",
    category: "Romantic",
    image: popularbook8,
  },
];

// Quote Data .......................

export const quoteData = [
  {
    id: 1,
    quote:
      "“The more that you read, the more things you will know. The more that you learn, the more places you’ll go.”",
    speaker: "Dr. Seuss",
  },
];

export const lettestArticleData = [
  {
    id: 1,
    title: "Reading books always makes the moments happy",
    image: ArticleImage1,
    titLink: "#",
    date: "2 aug, 2021",
    inspiration: "Inspiration",
    fbLink: "*",
    instLink: "*",
    twitaLink: "*",
  },
  {
    id: 2,
    image: ArticleImage2,
    title: "Reading books always makes the moments happy",
    titleLink: "*",
    date: "2 aug, 2021",
    inspiration: "Inspiration",
    fbLink: "*",
    instLink: "*",
    twitaLink: "*",
  },
  {
    id: 3,
    image: ArticleImage3,
    title: "Reading books always makes the moments happy",
    titleLink: "*",
    date: "2 aug, 2021",
    inspiration: "Inspiration",
    fbLink: "*",
    instLink: "*",
    twitaLink: "*",
  },
];

// Footers Data .......................
export const FootersLinksData = {
  Aboutus: [
    { linkname: "vision ", link: "*" },
    { linkname: "articles ", link: "*" },
    { linkname: "careers ", link: "*" },
    { linkname: "service terms ", link: "*" },
    { linkname: "donate ", link: "*" },
  ],
  Discover: [
    { linkname: "Home ", link: "*" },
    { linkname: "articles ", link: "*" },
    { linkname: "Books ", link: "*" },
    { linkname: "Authors  ", link: "*" },
    { linkname: "Subjects ", link: "*" },
    { linkname: "Advanced Search ", link: "*" },
  ],
  Myaccount: [
    { linkname: "Sign In", link: "*" },
    { linkname: "articles ", link: "*" },
    { linkname: "View Cart", link: "*" },
    { linkname: "My Wishtlist  ", link: "*" },
    { linkname: "Track My Order ", link: "*" },
  ],

  Help: [
    { linkname: "Help center ", link: "*" },
    { linkname: "Report a problem  ", link: "*" },
    { linkname: "View Cart", link: "*" },
    { linkname: "Suggesting edits ", link: "#" },
    { linkname: "Contact us", link: "*" },
  ],

  socials: [
    { icon: ImFacebook, link: "https://www.facebook.com" },
    { icon: FiInstagram, link: "https://www.twitter.com" },
    { icon: GrLinkedinOption, link: "https://www.instagram.com" },
    { icon: RiTwitterXLine, link: "https://www.twitter.com" },
    { icon: ImBehance, link: "https://www.twitter.com" },
  ],
};
