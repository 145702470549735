import React from "react";
import "./product.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination } from "swiper/modules";

const GallerySwiper = ({ images, setSetImage }) => {
  return (
    <div className="product-gallery-container-product">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={window.innerWidth <= 768 ? 40 : 20}
        slidesPerView={window.innerWidth <= 768 ? 1 : 4} // Show one image per
        pagination={{ clickable: true }} // Enable pagination
        navigation={{
          // Add navigation configuration
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        onSlideChange={() => console.log("Slide changed")}
        onSwiper={(swiper) => console.log("Swiper initialized", swiper)}
      >
        {images?.map((d) => (
          <SwiperSlide key={d.image}>
            <div
              className="clothing-item-garments"
              onClick={() => setSetImage(d.image)}
            >
              <img
                src={`https://hash-stitch.onrender.com${d.image}`}
                alt="image"
                className="clothing-image"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default GallerySwiper;
